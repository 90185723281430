<template>
  <app-module-view>
    <template slot="buttons">
      <app-button-create
        route-name="feUserDeliveryAddress_new"
      >
      </app-button-create>
    </template>
    <template slot="body">
      <app-feUser-filter v-if="!feUserId"></app-feUser-filter>
      <div class="card">
        <div class="card-body">
          <app-preloader v-if="callingAPI"></app-preloader>
          <app-data-table
            :data="feUserDeliveryAddresses"
            :totalCount="totalCount"
            :page="page"
            :config="dataTableConfig"
            @page-change="setPageAndGetRecords"
          >
          </app-data-table>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../../ModuleView'
import { mapState } from 'vuex'
import config from '../../../config'
import Preloader from '../../preloader/Preloader'
import FeUserDeliveryAddressFilter from './FeUserDeliveryAddressFilter'
import DataTable from '../../shared/DataTable'
import AboCountryStoreService from '../../../services/feUser/AboCountryStoreService'
import ButtonCreate from '../../shared/ButtonCreate'

export default {
  name: 'FeUserDeliveryAddressList',
  props: {
    feUserId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      page: 1,
      dataTableConfig: {
        parent: this,
        fields: {
          id: this.$t('feUser.id'),
          name: this.$t('feUser.name'),
          surname: this.$t('feUser.surname'),
          street: this.$t('feUser.street'),
          city: this.$t('feUser.city'),
          zip: this.$t('feUser.zip'),
          aboCountry: this.$t('feUser.aboCountry'),
          phone: this.$t('feUser.phone'),
          feUserExpanded: this.$t('feUser.user_email')
        },
        actions: {
          edit: 'feUserDeliveryAddress_edit'
        },
        render: {
          aboCountry: function (data) {
            return (data) ? AboCountryStoreService.getCountryNameById(data) : ''
          }
        }
      }
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    aboCountries () {
      return this.$store.getters['aboCountry/list']
    },
    feUserDeliveryAddresses () {
      return this.$store.getters['feUserDeliveryAddress/list']
    },
    totalCount () {
      return this.$store.getters['feUserDeliveryAddress/totalCount']
    },
    pageCount () {
      return Math.ceil(this.$store.getters['feUserDeliveryAddress/totalCount'] / config.defaults.list.limit)
    }
  },
  components: {
    appModuleView: ModuleView,
    appPreloader: Preloader,
    appFeUserFilter: FeUserDeliveryAddressFilter,
    appDataTable: DataTable,
    appButtonCreate: ButtonCreate
  },
  methods: {
    getFeUserDeliveryAddresses () {
      if (this.feUserId > 0) {
        this.$store.commit('feUserDeliveryAddress/setFilter', { feUser: this.feUserId })
      }
      this.$store.dispatch('feUserDeliveryAddress/fetch')
    },
    setPageAndGetRecords (page) {
      this.page = page
      this.$store.commit('feUserDeliveryAddress/setPage', page)
      this.getFeUserDeliveryAddresses()
    }
  },
  mounted () {
    this.page = this.$store.getters['feUserDeliveryAddress/page']
    this.$store.commit('feUserDeliveryAddress/setFilter', {})
    this.getFeUserDeliveryAddresses()
    this.$store.dispatch('aboCountry/fetch', '')
  }
}
</script>

<style lang="scss" scoped>

</style>
